import { ref } from '@vue/composition-api'

export default function useExerciseResultAttachment() {
  const attachmentExerciseResultId = ref(null)
  const editType = ref(null)
  const editingAttachment = ref(null)

  return {
    attachmentExerciseResultId,
    editType,
    editingAttachment,
  }
}

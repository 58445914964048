<template>
  <div>

    <h2 class="mb-2">
      {{ $t('meeting.materials.title') }}
    </h2>

    <b-row>

      <!-- Texts -->
      <b-col
        v-if="texts.length"
        cols="12"
        xl="10"
        class="mb-3"
      >
        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.text.title-plural') }}
          </h3>
        </b-row>

        <!-- collapse -->
        <app-collapse
          accordion
          type="margin"
        >

          <app-collapse-item
            v-for="attachment in texts"
            :key="attachment.id"
            :title="attachment.title"
          >

            <div v-html="attachment.content" />

          </app-collapse-item>

        </app-collapse>

      </b-col>

      <!-- Files -->
      <b-col
        v-if="files.length"
        cols="12"
        xl="10"
        class="mb-1"
      >

        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.file.title-plural') }}
          </h3>
        </b-row>

        <b-row class="match-height">
          <b-col
            v-for="attachment in files"
            :key="attachment.id"
            sm="12"
            xl="6"
          >
            <b-card>
              <b-link @click="download(attachment)">
                <feather-icon icon="FileIcon" />
                {{ attachment.title }}
              </b-link>
            </b-card>
          </b-col>
        </b-row>

      </b-col>

      <!-- Links -->
      <b-col
        v-if="links.length"
        cols="12"
        xl="10"
        class="mb-1"
      >
        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.link.title-plural') }}
          </h3>
        </b-row>

        <b-row
          class="match-height"
        >
          <b-col
            v-for="attachment in links"
            :key="attachment.id"
            sm="12"
            md="6"
          >
            <url-attachment-preview
              :attachment="attachment"
            />
          </b-col>
        </b-row>

      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBModal, BLink,
} from 'bootstrap-vue'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import UrlAttachmentPreview from '@/views/_global/UrlAttachmentPreview'
import materialsSetup from '@/views/customer/meeting/tabs/materials/materialsSetup'

export default {
  components: {
    UrlAttachmentPreview,
    BRow,
    BCol,
    BCard,
    BLink,

    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    globalProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup: materialsSetup,
}
</script>

<template>
  <div>

    <add-attachment-modal
      v-if="attachmentExerciseResultId"
      resource="exercise-result"
      :resource-id="attachmentExerciseResultId"
      type="file"
      :types="['file']"
      :attachment-organization-id="meeting.calendar.owner.organization.id"
      :attachment-list="attachmentList"
    />

    <edit-attachment-modal
      v-if="attachmentExerciseResultId"
      v-model="editingAttachment"
      resource="exercise-result"
      :resource-id="attachmentExerciseResultId"
      type="file"
    />

    <attachment-event-handler
      @start-processing="processing = true"
      @stop-processing="processing = false"
    />

    <b-row>
      <b-col
        cols="12"
        xl="10"
        class="mb-2"
      >
        <h1>
          {{ $t('meeting.exercise.title') }}
        </h1>

        <div
          v-if="processing"
          class="my-5 text-center"
        >
          <b-spinner />
        </div>

      </b-col>
    </b-row>

    <b-row
      v-for="calendarEventCustomer in meeting.calendarEventCustomerObjects"
      :key="`calendar-event-customer-${calendarEventCustomer.id}`"
    >
      <b-col
        v-if="meeting.calendarEventCustomers.length > 1"
        cols="12"
        xl="10"
        class="mb-1"
      >
        <h3>{{ calendarEventCustomer.customer.lastName }} {{ calendarEventCustomer.customer.firstName }}</h3>
      </b-col>

      <b-col
        v-for="exerciseResult in calendarEventCustomer.exerciseResults"
        :key="`exercise-result-${exerciseResult.id}`"
        cols="12"
        xl="10"
      >
        <b-card :title="exerciseResult.exercise.title">

          <div
            v-if="exerciseResult.exercise.description"
            class="mb-2"
          >
            <div v-html="exerciseResult.exercise.description" />
          </div>

          <div
            v-if="exerciseResult.exercise.attachments && exerciseResult.exercise.attachments.length"
            class="mb-2"
          >

            <div
              v-for="(attachment) in exerciseResult.exercise.attachments"
              :key="'exercise-attachment-' + attachment.id"
              class="d-inline"
            >
              <b-button
                v-if="attachment.type === 'ExerciseAttachmentFile'"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="mr-1"
                @click="download(attachment)"
              >
                <feather-icon icon="PaperclipIcon" />
                {{ attachment.title }}
              </b-button>

              <b-button
                v-if="attachment.type === 'ExerciseAttachmentUrl'"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="mr-1"
                :href="attachment.url"
                target="_blank"
              >
                <feather-icon icon="ExternalLinkIcon" />
                {{ attachment.title }}
              </b-button>
            </div>

          </div>

          <div class="mb-2">
            <h6 class="section-label">
              {{ $t('meeting.exercise.solution') }}
            </h6>

            <text-editor
              :id="`solution-text-content-${exerciseResult.id}`"
              v-model="exerciseResult.solution"
              variant="simple"
              @change="changeHandler(exerciseResult)"
            />

            <p class="text-right mt-n1">
              <b-link
                v-b-modal.add-attachment-modal
                @click="addAttachmentLocal(exerciseResult.id, exerciseResult.attachments)"
              >
                <small>
                  <feather-icon icon="PlusIcon" /> {{ $t('script.btn.add-attachment').toLowerCase() }}
                </small>
              </b-link>
            </p>

          </div>

          <div
            v-if="exerciseResult.attachments && exerciseResult.attachments.length"
            class="mt-n1 mb-2"
          >

            <div>
              <b-button-group
                v-for="(attachment) in exerciseResult.attachments"
                :key="'attachment-' + attachment.id"
                class="mr-1 mb-1"
              >
                <b-dropdown
                  v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                  variant="outline-secondary"
                  split
                  size="sm"
                  @click="download(attachment)"
                >
                  <template
                    #button-content
                  >
                    <div class="text-left">
                      <feather-icon icon="PaperclipIcon" />
                      {{ attachment.title }}
                    </div>
                  </template>
                  <b-dropdown-item
                    v-b-modal.edit-attachment-modal
                    @click="editAttachmentLocal(attachment, exerciseResult.attachments, 'file')"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                    {{ $t('shared.edit') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="deleteAttachment(attachment, exerciseResult.attachments);"
                  >
                    <feather-icon
                      icon="TrashIcon"
                    />
                    {{ $t('shared.btn.delete') }}
                  </b-dropdown-item>
                </b-dropdown>

              </b-button-group></div>

          </div>

          <div v-if="exerciseResult.rating">
            <h6 class="section-label">
              {{ $t('meeting.exercise.rating.title') }}
            </h6>

            <b-form-rating
              v-model="exerciseResult.rating"
              variant="warning"
              inline
              size="xl"
              class="mb-1"
              readonly
            />
          </div>

          <div
            v-if="exerciseResult.comment"
            class="mb-2"
          >
            <div v-html="exerciseResult.comment" />
          </div>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCard, BCol, BDropdown, BDropdownItem, BFormRating, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TextEditor from '@/views/_global/TextEditor'
import exercisesSetup from '@/views/customer/meeting/tabs/exercises/exercisesSetup'
import AddAttachmentModal from '@/views/_global/AddAttachmentModal'
import EditAttachmentModal from '@/views/_global/EditAttachmentModal'
import AttachmentEventHandler from '@/views/_global/AttachmentEventHandler'

export default {
  components: {
    BFormRating,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BLink,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,

    AddAttachmentModal,
    EditAttachmentModal,
    AttachmentEventHandler,

    TextEditor,
  },
  directives: {
    Ripple,
  },
  setup: exercisesSetup,
}
</script>

<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="5"
        xl="4"
      >
        <b-row>
          <b-col>

            <b-card v-if="meetingEndsAt">
              <timer
                :show="true"
                :time="meetingEndsAt"
              />
            </b-card>

            <meeting-details
              :meeting="meeting"
              :processing="processing"
            />

          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="7"
        xl="6"
      >

        <b-row>

          <!-- Script details -->
          <b-col
            v-if="meeting.script.id"
            cols="12"
          >
            <b-card
              :title="meeting.script.subject"
              :sub-title="meeting.script.scriptGroup ? meeting.script.scriptGroup.name: null"
            >
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <div v-if="meeting.script.id">
                  <div v-if="(meeting.script.scriptGroup && meeting.script.scriptGroup.coursebook) || meeting.script.coursebookNotes">
                    <b-row>
                      <b-col v-if="meeting.script.scriptGroup.coursebook">
                        <h5>{{ $t('script.group.label.coursebook') }}</h5>
                        <p>
                          {{ meeting.script.scriptGroup.coursebook }}
                        </p>
                      </b-col>
                      <b-col v-if="meeting.script.coursebookNotes">
                        <h5>{{ $t('script.scripts.label.coursebook-notes') }}</h5>
                        <p>
                          {{ meeting.script.coursebookNotes }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>

                </div>
              </rc-overlay>

            </b-card>
          </b-col>

          <!-- Script content -->
          <b-col
            v-if="meeting.script.content"
            cols="12"
          >
            <b-card :title="$t('meeting.general.lesson-purpose')">
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <div v-html="meeting.script.content" />
              </rc-overlay>
            </b-card>
          </b-col>

        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import Timer from '@/layouts/components/rc/Timer'
import MeetingDetails from '@/views/customer/meeting/partials/MeetingDetails'
import generalSetup from '@/views/customer/meeting/tabs/general/generalSetup'

export default {
  components: {
    MeetingDetails,

    RcOverlay,
    Timer,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    globalProcessing: {
      type: Boolean,
      default: false,
    },
  },
  setup: generalSetup,
}
</script>

import {
  computed, ref,
} from '@vue/composition-api'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'
import useAttachment from '@/views/_global/useAttachment'
import useExerciseResultAttachment from '@/views/customer/meeting/tabs/exercises/useExerciseResultAttachment'

export default (__, { emit }) => {
  const meeting = computed(() => store.getters['customers/getCustomerCalendarEvent'])
  const processing = ref(false)

  const timeouts = {}

  const {
    attachmentExerciseResultId,
  } = useExerciseResultAttachment()

  const {
    download,
    addAttachment,
    editAttachment,
    deleteAttachment,
    editingAttachment,
    attachmentList,
  } = useAttachment(emit)

  const editAttachmentLocal = (attachment, attachments, type = 'file') => {
    attachmentExerciseResultId.value = attachment.id
    editAttachment(attachment, attachments, type)
  }

  const addAttachmentLocal = (itemId, attachments, type = 'file') => {
    attachmentExerciseResultId.value = itemId
    addAttachment(attachments, type)
  }

  const saveChanges = exerciseResult => {
    store.dispatch(
      'customers/patchCustomerExerciseResult',
      {
        id: exerciseResult.id,
        data: {
          solution: exerciseResult.solution,
        },
      },
    )
      .catch(err => parseRequestError(err))
  }

  const changeHandler = exerciseResult => {
    clearTimeout(timeouts[exerciseResult.id])
    timeouts[exerciseResult.id] = setTimeout(() => {
      saveChanges(exerciseResult)
    }, 2500)
  }

  return {
    meeting,

    processing,
    changeHandler,
    attachmentExerciseResultId,
    attachmentList,

    addAttachmentLocal,
    editAttachmentLocal,
    deleteAttachment,
    editingAttachment,
    download,
  }
}

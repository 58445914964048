import {
  computed, ref,
} from '@vue/composition-api'
import store from '@/store'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/dateTimeHelper'

export default props => {
  const localProcessing = ref(false)
  const processing = computed(() => props.globalProcessing || localProcessing.value)

  const meeting = computed(() => store.getters['customers/getCustomerCalendarEvent'])
  const meetingId = computed(() => meeting.value.id)

  return {
    processing,
    meeting,
    meetingId,

    transformDatetimeToHumanReadablePretty,
  }
}

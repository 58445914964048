<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        class="mb-2"
      >
        <h1>
          {{ $t('meeting.meeting-summary.title') }}
        </h1>
      </b-col>
    </b-row>

    <b-row v-show="meeting.calendarEventCustomers.length">

      <b-col
        cols="12"
        xl="10"
      >
        <div
          v-for="(calendarEventCustomer) in meeting.calendarEventCustomerObjects"
          :key="`attendee-${calendarEventCustomer.id}`"
        >
          <h3
            v-if="meeting.calendarEventCustomers.length > 1"
            class="mb-1"
          >
            {{ calendarEventCustomer.customer.lastName }} {{ calendarEventCustomer.customer.firstName }}
          </h3>

          <b-card
            v-if="meeting.meetingNote || calendarEventCustomer.meetingNote"
            :title="$t('meeting.meeting-summary.summary')"
          >
            <div v-html="meeting.meetingNote" />
            <div v-html="calendarEventCustomer.meetingNote" />
          </b-card>

          <b-card
            v-if="calendarEventCustomer.knowledgeCheckRatings.length"
            :title="$t('script.knowledge-checklist.title')"
          >
            <div
              v-for="knowledgeCheckRating in calendarEventCustomer.knowledgeCheckRatings"
              :key="`knowledge-check-rating-${knowledgeCheckRating.id}`"
              class="mb-2"
            >
              <h5>{{ knowledgeCheckRating.knowledgeCheck.title }}</h5>

              <div v-html="knowledgeCheckRating.knowledgeCheck.description" />

              <div v-if="knowledgeCheckRating.rating || knowledgeCheckRating.comment">

                <h6 class="section-label">
                  {{ $t('meeting.knowledge-check.rating.title') }}
                </h6>

                <b-form-rating
                  v-model="knowledgeCheckRating.rating"
                  variant="warning"
                  inline
                  size="xl"
                  class="mb-1"
                  readonly
                />

                <div
                  v-if="knowledgeCheckRating.comment"
                  class="mb-2"
                >
                  <div v-html="knowledgeCheckRating.comment" />
                </div>

              </div>

            </div>
          </b-card>

        </div>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCol, BFormRating, BRow,
} from 'bootstrap-vue'
import meetingSummarySetup from '@/views/customer/meeting/tabs/meeting-summary/meetingSummarySetup'

export default {
  components: {
    BFormRating,
    BRow,
    BCol,
    BCard,
  },
  setup: meetingSummarySetup,
}
</script>

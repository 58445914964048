<template>
  <b-tabs
    :key="`meeting-view-${$route.params.id}`"
    vertical
    pills
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab
      active
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.general.title') }}
        </span>
      </template>

      <general :global-processing="processing" />
    </b-tab>
    <!--/ general tab -->

    <!-- materials tab -->
    <b-tab
      :disabled="!showMaterialsTab"
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="PaperclipIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.materials.title') }}
        </span>
      </template>

      <materials :global-processing="processing" />
    </b-tab>
    <!--/ materials tab -->

    <!-- exercises tab -->
    <b-tab
      :disabled="!showExercisesTab"
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.exercise.title') }}
        </span>
      </template>

      <exercises :global-processing="processing" />
    </b-tab>
    <!--/ exercises tab -->

    <!-- meeting summary tab -->
    <b-tab
      :disabled="!showSummaryTab"
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.meeting-summary.title') }}
        </span>
      </template>

      <meeting-summary :global-processing="processing" />
    </b-tab>
    <!--/ meeting summary tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import General from '@/views/customer/meeting/tabs/general/General'
import Materials from '@/views/customer/meeting/tabs/materials/Materials'
import Exercises from '@/views/customer/meeting/tabs/exercises/Exercises'
import MeetingSummary from '@/views/customer/meeting/tabs/meeting-summary/MeetingSummary'
import store from '@/store'

export default {
  components: {
    BTabs,
    BTab,
    General,
    Materials,
    Exercises,
    MeetingSummary,
  },

  watch: {
    $route(to, from) {
      if (to.params.id === from.params.id) {
        return
      }

      this.init(to.params.id)
    },
  },
  setup: (_, { root }) => {
    const route = root.$route

    const meetingId = computed(() => parseInt(route.params.id, 10))
    const meeting = computed(() => store.getters['customers/getCustomerCalendarEvent'])
    const processing = ref(false)

    const showMaterialsTab = computed(() => (meeting.value.script.attachments?.length || meeting.value.attachments?.length))
    const showExercisesTab = computed(() => Object.values(meeting.value.calendarEventCustomerObjects).some(cec => cec.exerciseResults?.length))
    const showSummaryTab = computed(() => Object.values(meeting.value.calendarEventCustomerObjects).some(cec => cec.knowledgeCheckRatings?.length)
      || meeting.value.meetingNote)
    const showAnyTab = computed(() => showMaterialsTab.value || showExercisesTab.value || showSummaryTab.value)

    const init = id => {
      processing.value = true
      Promise.all([
        store.dispatch('customers/fetchCustomerCalendarEvent', id),
      ])
        .then(() => {
          processing.value = false
        })
    }

    init(meetingId.value)

    return {
      processing,
      meetingId,
      meeting,

      showMaterialsTab,
      showExercisesTab,
      showSummaryTab,
      showAnyTab,

      init,
    }
  },
}
</script>

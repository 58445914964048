<template>
  <div v-if="visible">
    <div
      :class="color"
      class="text-nowrap font-large-2"
    >
      <span v-if="hours > 0">{{ sprintf('%02d', hours) }} : </span>
      {{ sprintf('%02d', minutes > 0 ? minutes : 0) }} : {{ sprintf('%02d', seconds > 0 ? seconds : 0) }}
    </div>
  </div>
</template>

<script>

import { sprintf } from 'printj'
import dayjs from 'dayjs'

export default {
  props: {
    time: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interval: null,
      diff: 12 * 60 * 60,
    }
  },
  computed: {
    visible() {
      return this.show && this.hours < 12
    },
    timeObject() {
      return dayjs(this.time)
    },
    hours() {
      return Math.floor(this.diff / 3600)
    },
    minutes() {
      return Math.floor((this.diff % 3600) / 60)
    },
    seconds() {
      return Math.floor(this.diff % 60)
    },
    color() {
      return this.diff > 300 ? 'text-success' : 'text-danger'
    },
  },
  mounted() {
    this.diff = this.getDiff()
    this.interval = setInterval(() => {
      this.diff = this.getDiff()
    }, 1000)
  },
  methods: {
    sprintf,
    getDiff() {
      return this.timeObject.diff(dayjs(), 'seconds')
    },
  },
}
</script>

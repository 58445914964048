import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { cloneNested } from '@/helpers/helpers'
import useAttachment from '@/views/_global/useAttachment'

export default (props, { emit }) => {
  const meeting = computed(() => store.getters['customers/getCustomerCalendarEvent'])
  const meetingId = computed(() => meeting.value.id)

  const {
    groupAttachments,
    download,
  } = useAttachment(emit)

  const loading = ref(false)

  const links = computed(() => {
    const localScriptAttachments = groupAttachments(meeting.value.script?.attachments).ScriptAttachmentUrl ?? []
    const localCalendarEventAttachments = groupAttachments(meeting.value.attachments).CalendarEventAttachmentUrl ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })
  const files = computed(() => {
    const localScriptAttachments = groupAttachments(meeting.value.script?.attachments).ScriptAttachmentFile ?? []
    const localCalendarEventAttachments = groupAttachments(meeting.value.attachments).CalendarEventAttachmentFile ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })
  const texts = computed(() => {
    const localScriptAttachments = groupAttachments(meeting.value.script?.attachments).ScriptAttachmentText ?? []
    const localCalendarEventAttachments = groupAttachments(meeting.value.attachments).CalendarEventAttachmentText ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })

  return {
    meeting,
    meetingId,

    loading,
    links,
    files,
    texts,

    download,

    cloneNested,
  }
}

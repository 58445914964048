import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'

export default props => {
  const localProcessing = ref(false)
  const processing = computed(() => props.globalProcessing || localProcessing.value)
  const forceChangeScript = ref(false)
  const viewNavigation = ref()

  const meeting = computed(() => store.getters['customers/getCustomerCalendarEvent'])
  const meetingEndsAt = computed(() => {
    if (meeting.value.startedAt) {
      const endsAt = dayjs(meeting.value.startedAt).add(meeting.value.service.duration, 'minute')
      if (endsAt.diff() < 0) {
        return false
      }

      return endsAt.toJSON()
    }
    return null
  })
  const script = computed(() => store.getters['script/getScript'])

  return {
    processing,
    forceChangeScript,

    meeting,
    viewNavigation,
    meetingEndsAt,
    script,
  }
}
